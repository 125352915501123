import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuardService} from './services/guard/auth-guard.service';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( (m: any)  => m.HomePageModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( (m: any)  => m.LoginPageModule),
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then( (m: any)  => m.SignupPageModule),
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./reset-password/reset-password.module').then( (m: any)  => m.ResetPasswordPageModule),
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then( (m: any)  => m.TabsPageModule),
  },
  {
    path: 'response-payment',
    loadChildren: () => import('./response-payment/response-payment.module').then( (m: any) => m.ResponsePaymentPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'success',
    loadChildren: () => import('./success/success.module').then( (m: any)  => m.SuccessPageModule),
  },
  {
    path: 'activation',
    loadChildren: () => import('./activation/activation.module').then( (m: any)  => m.ActivationPageModule),
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }

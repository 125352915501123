import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { User } from 'src/app/models/user';
import * as moment from 'moment';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  public authState = new BehaviorSubject(false);
  private keyUser: string = 'g4rz0n-4pp-10n1c';
  private keyTime: string = 'g4rz0n-4pp-10n1c-open-app';
  private wallets: string = 'g4rz0n-4pp-10n1c-wallets';
  private wallet: string = 'g4rz0n-4pp-10n1c-wallet';
  public user: User;

  constructor(private storage: Storage) { }

  public isAuthenticated() {
   return this.authState.value;
  }

  public inicializeUser() {
    return new Promise((resolve, reject) => {
      this.storage.get(this.keyUser).then((val) => {
        if(!val) {
          reject('not session');
        } else {
          this.user = val;
          this.authState.next(true);
          resolve(this.user);
        }
      });
    });
  }

  public getKeyTime() {
    return this.storage.get(this.keyTime);
  }

  public setWallets(wallets) {
    this.storage.set(this.wallets, wallets);
  }

  public getWallets() {
    return this.storage.get(this.wallets);
  }

  public deleteWallets() {
    return this.storage.remove(this.wallets);
  }

  public setWallet(wallet) {
    this.storage.set(this.wallet, wallet);
  }

  public getWallet() {
    return this.storage.get(this.wallet);
  }

  public deleteWallet() {
    return this.storage.remove(this.wallet);
  }

  public setDate() {
    this.storage.set(this.keyTime, moment().format('YYYY-MM-DD H:mm:ss'));
  }

  public setUser(user: any) {
    this.setDate();
    return this.storage.set(this.keyUser, user);
  }

  public removeSesion() {
    return this.storage.remove(this.keyUser);
  }

  public decodeToken(token) {
    return jwt_decode(token);
  }
}
